<script setup lang="ts">
import Container from '@/components/container/Container.vue'
import Login from '@/views/login/Login.vue'
import { useCommonStore } from '@/stores'
import { computed } from 'vue'

const commonStore = useCommonStore()
const isLogged = computed(() => commonStore.isLogged)
</script>

<template>
  <Container v-if="isLogged" />
  <Login v-else />
</template>
