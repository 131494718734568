<style scoped>
.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card {
  width: 680px;
}
.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 40px 0;
  box-sizing: border-box;
}
.img {
  width: 200px;
  object-fit: cover;
  flex-shrink: 0;
  margin-right: 16px;
}
.content {
  flex: 1;
}
.select {
  margin-bottom: 24px;
}
</style>

<template>
  <div class="container">
    <Card class="card">
      <div class="wrapper">
        <img class="img" src="@/assets/logo-chat.png" />
        <div class="content">
          <Select class="select" v-model="app" @on-change="onChange" size="large">
            <Option v-for="item in Object.keys(APP_MAP)" :key="item" :value="item">{{ item }}</Option>
          </Select>
          <Login @on-submit="handleSubmit">
            <Email name="email" />
            <Password name="password" enter-to-submit />
            <Submit :loading="isLoading" />
          </Login>
        </div>
      </div>
    </Card>
  </div>
</template>

<script setup lang="ts">
import { APP_MAP } from '@/utils'
import { usePost } from '@/hooks'
import { useCommonStore } from '@/stores'
import { ref, getCurrentInstance, type ComponentInternalInstance } from 'vue'

const { proxy }: ComponentInternalInstance = getCurrentInstance()!
const commonStore = useCommonStore()
const post = usePost()
const isLoading = ref(false)
const app = ref(commonStore.app)

const onChange = (value: string) => {
  commonStore.setApp(value)
}
const handleSubmit = async (valid: boolean, { email, password }: { email: string; password: string }) => {
  if (valid) {
    try {
      isLoading.value = true
      const { token } = await post<{ token: string }>('/auth', { email, password })
      isLoading.value = false
      commonStore.setToken(token)
      proxy?.$Message.success({ content: '欢迎回来👏' })
    } catch (error) {
      isLoading.value = false
    }
  }
}
</script>
