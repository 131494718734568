import { useCommonStore } from '@/stores'
import { APP_MAP } from '@/utils/constants'
import { getCurrentInstance, type ComponentInternalInstance } from 'vue'

type method = 'GET' | 'POST'

async function http(
  instance: ComponentInternalInstance,
  method: method,
  path: string,
  headers: Record<string, string> = {},
  searchParams?: Record<string, string | number>,
  body?: BodyInit | null
) {
  const { proxy } = instance
  const commonStore = useCommonStore()
  let url = APP_MAP[commonStore.app as keyof typeof APP_MAP] + path

  if (searchParams) {
    const params = Object.keys(searchParams)
      .map((k) => `${k}=${searchParams[k]}`)
      .join('&')

    url = `${url}?${params}`
  }

  const options: RequestInit = {
    method,
    headers: {
      Authorization: commonStore.token || '',
      ...headers
    },
    body
  }

  try {
    const resp = await fetch(url, options)
    const data = await responseHandler(resp, commonStore.clearToken)

    return Promise.resolve(data)
  } catch (error) {
    proxy?.$Message.error({ content: (error as Error).message })
    return Promise.reject(error)
  }
}

async function responseHandler(resp: Response, tokenClear: () => void) {
  if (resp.status === 401 || resp.status === 400) {
    tokenClear()

    return Promise.reject(new Error('登录状态失效，请重新登录'))
  }

  if (!resp.ok) {
    return Promise.reject(new Error(`请求错误：${resp.statusText}`))
  }

  try {
    const { status, message, data } = await resp.json()

    if (!status) {
      return Promise.reject(new Error(message))
    }

    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export function useGet() {
  const instance: ComponentInternalInstance = getCurrentInstance()!

  return <T>(path: string, searchParams?: Record<string, string | number>): Promise<T> => {
    return http(instance, 'GET', path, {}, searchParams) as Promise<T>
  }
}

export function usePost() {
  const instance: ComponentInternalInstance = getCurrentInstance()!

  return <T>(path: string, body?: Record<string, string | number>): Promise<T> => {
    return http(instance, 'POST', path, {}, undefined, body ? JSON.stringify(body) : body) as Promise<T>
  }
}
