import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useCommonStore = defineStore('common', () => {
  const app = ref(localStorage.getItem('app') || 'ChatBot')
  const token = ref(localStorage.getItem('token'))
  const isLogged = computed(() => !!token.value)

  function setApp(value: string) {
    app.value = value
    localStorage.setItem('app', value)
  }
  function setToken(value: string) {
    token.value = value
    localStorage.setItem('token', value)
  }
  function clearToken() {
    token.value = ''
    localStorage.removeItem('token')
  }

  return {
    app,
    token,
    isLogged,
    setApp,
    setToken,
    clearToken
  }
})
