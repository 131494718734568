<style scoped>
.layout-con {
  height: 100%;
  width: 100%;
}
.menu-item span {
  display: inline-block;
  overflow: hidden;
  width: 69px;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: bottom;
  transition: width 0.2s ease 0.2s;
}
.menu-item i {
  transform: translateX(0px);
  transition: font-size 0.2s ease, transform 0.2s ease;
  vertical-align: middle;
  font-size: 16px;
}
.collapsed-menu span {
  width: 0px;
  transition: width 0.2s ease;
}
.collapsed-menu i {
  transform: translateX(5px);
  transition: font-size 0.2s ease 0.2s, transform 0.2s ease 0.2s;
  vertical-align: middle;
  font-size: 22px;
}
.dev-run-preview .dev-run-preview-edit {
  display: none;
}
.header {
  background: #fff;
  box-shadow: 0 2px 3px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.app {
  font-weight: 500;
  font-size: 18px;
  margin: 0 auto;
}
.icon-logout {
  margin-right: 6px;
}
</style>

<template>
  <div class="layout">
    <Layout :style="{ minHeight: '100vh' }">
      <Sider collapsible :collapsed-width="130" v-model="isCollapsed">
        <Menu accordion active-name="1-1" theme="dark" width="auto" :class="menuitemClasses">
          <Submenu name="1">
            <template #title>
              <Icon type="ios-analytics" />
              <span>Dashboard</span>
            </template>
            <MenuItem name="1-1" to="/console">主控台</MenuItem>
            <MenuItem name="1-2" to="/monitor">监控页</MenuItem>
            <MenuItem name="1-3" to="/workbench">工作台</MenuItem>
          </Submenu>
          <Submenu name="2">
            <template #title>
              <Icon type="ios-people" />
              <span>用户管理</span>
            </template>
            <MenuItem name="2-1" to="/customer-info">用户</MenuItem>
            <MenuItem name="2-2" to="/roles-info">角色</MenuItem>
          </Submenu>
          <Submenu name="3">
            <template #title>
              <Icon type="ios-apps" />
              <span>积分管理</span>
            </template>
            <MenuItem name="3-1" to="/purchase">购买</MenuItem>
            <MenuItem name="3-2" to="/refound">退款</MenuItem>
            <MenuItem name="3-3" to="/reward-punish">奖励/惩罚</MenuItem>
            <MenuItem name="3-4" to="/set">⚠️ 修改</MenuItem>
          </Submenu>
          <Submenu name="4">
            <template #title>
              <Icon type="ios-cash" />
              <span>财务管理</span>
            </template>
            <MenuItem name="4-1" to="/report">填报</MenuItem>
          </Submenu>
          <Submenu name="5">
            <template #title>
              <Icon type="ios-basket" />
              <span>交易记录</span>
            </template>
            <MenuItem name="5-1" to="/billing">账单</MenuItem>
            <MenuItem name="5-2" to="/points">积分</MenuItem>
            <MenuItem name="5-3" to="/finance">财务</MenuItem>
          </Submenu>
          <Submenu name="6">
            <template #title>
              <Icon type="ios-briefcase" />
              <span>工具箱</span>
            </template>
          </Submenu>
        </Menu>
      </Sider>
      <Layout>
        <Header class="header">
          <span class="app">{{ commonStore.app }}</span>
          <Dropdown style="margin-left: 20px">
            <Avatar src="https://i.loli.net/2017/08/21/599a521472424.jpg" />
            <template #list>
              <DropdownMenu>
                <DropdownItem @click="logout"><Icon class="icon-logout" type="ios-log-out" />退出登录</DropdownItem>
              </DropdownMenu>
            </template>
          </Dropdown>
        </Header>
        <Content :style="{ padding: '0 16px 16px' }">
          <Breadcrumb :style="{ margin: '16px 0' }">
            <BreadcrumbItem>Home</BreadcrumbItem>
            <BreadcrumbItem>Components</BreadcrumbItem>
            <BreadcrumbItem>Layout</BreadcrumbItem>
          </Breadcrumb>
          <Card>
            <RouterView />
          </Card>
        </Content>
      </Layout>
    </Layout>
  </div>
</template>

<script setup lang="ts">
import { useCommonStore } from '@/stores'
import { ref, computed, getCurrentInstance, type ComponentInternalInstance } from 'vue'

const { proxy }: ComponentInternalInstance = getCurrentInstance()!
const commonStore = useCommonStore()
const isCollapsed = ref(true)
const menuitemClasses = computed(() => ['menu-item', isCollapsed.value ? 'collapsed-menu' : ''])
const logout = () => {
  commonStore.clearToken()
  proxy?.$Message.success({ content: '再见👋' })
}
</script>
