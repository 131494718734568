import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router'
import Console from '@/views/dashboard/console/Console.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/console'
  },
  {
    path: '/console',
    name: 'console',
    component: Console
  },
  {
    path: '/monitor',
    name: 'monitor',
    component: () => import('@/views/dashboard/monitor/Monitor.vue')
  },
  {
    path: '/workbench',
    name: 'workbench',
    component: () => import('@/views/dashboard/workbench/Workbench.vue')
  },
  {
    path: '/purchase',
    name: 'purchase',
    component: () => import('@/views/points/purchase/Purchase.vue')
  },
  {
    path: '/refound',
    name: 'refound',
    component: () => import('@/views/points/refound/Refound.vue')
  },
  {
    path: '/reward-punish',
    name: 'reward-punish',
    component: () => import('@/views/points/rewardAndPunish/RewardAndPunish.vue')
  },
  {
    path: '/set',
    name: 'set',
    component: () => import('@/views/points/set/Set.vue')
  },
  {
    path: '/report',
    name: 'report',
    component: () => import('@/views/financial/report/Report.vue')
  },
  {
    path: '/billing',
    name: 'billing',
    component: () => import('@/views/records/billing/Billing.vue')
  },
  {
    path: '/points',
    name: 'points',
    component: () => import('@/views/records/points/Points.vue')
  },
  {
    path: '/finance',
    name: 'finance',
    component: () => import('@/views/records/finance/Finance.vue')
  },
  {
    path: '/customer-info',
    name: 'customerInfo',
    component: () => import('@/views/customer/info/Info.vue')
  },
  {
    path: '/roles-info',
    name: 'rolesInfo',
    component: () => import('@/views/roles/info/Info.vue')
  }
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})

export default router
